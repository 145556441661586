@media only screen and (min-width: 320px) {
  .Main {
    background-image: url("./image/main_image.png");
    background-repeat: no-repeat;
    background-position: top right;
    padding: 50px 0 40px 0;
    background-size: 60%;
  }

  .Main-header-logo {
    width: 200px;
  }

  .Main-header {
    display: flex;
    flex-direction: row;
    justify-content: space-between;
  }

  .Main-nav {
    display: none;
  }

  .Main_nav_item {
    color: #fff;
    text-decoration: none;
    margin-right: 10px;
  }

  .Main_title {
    margin-top: 70px;
    margin-bottom: 40px;
  }
}

@media only screen and (min-width: 600px) {
  .Main {
    background-image: url("./image/main_image.png");
    background-repeat: no-repeat;
    background-position: top right;
    padding: 50px 0 50px 0;
    background-size: 52%;
  }

  .Main-header-logo {
    width: 200px;
  }

  .Main-header {
    display: flex;
    flex-direction: row;
    justify-content: space-between;
  }


  .Main_nav_item {
    color: #fff;
    text-decoration: none;
    margin-right: 10px;
  }

  .Main_title {
    margin-top: 40px;
    margin-bottom: 40px;
  }
}

@media only screen and (min-width: 768px) {
  .Main {
    background-image: url("./image/main_image.png");
    background-repeat: no-repeat;
    background-position: top right;
    padding: 50px 0 50px 0;
    background-size: 58%;
  }

  .Main-header-logo {
    width: 300px;
  }

  .Main-header {
    display: flex;
    flex-direction: row;
    justify-content: space-between;
  }

  .Main-nav {
    display: block;
  }

  .Main_nav_item {
    color: #fff;
    text-decoration: none;
    margin-right: 10px;
  }

  .Main_title {
    margin-top: 80px;
    margin-bottom: 80px;
  }
}

@media only screen and (min-width: 992px) {
  .Main {
    background-image: url("./image/main_image.png");
    background-repeat: no-repeat;
    background-position: top right;
    padding: 50px 0 144px 0;
    background-size: 58%;
  }

  .Main-header-logo {
    width: 400px;
  }



  .Main-header {
    display: flex;
    flex-direction: row;
    justify-content: space-between;
  }

  .Main_nav_item {
    color: #fff;
    text-decoration: none;
    margin-right: 10px;
  }

  .Main_title {
    margin-top: 120px;
    margin-bottom: 120px;
  }
}


@media only screen and (min-width: 1200px) {
  .Main {
    background-image: url("./image/main_image.png");
    background-repeat: no-repeat;
    background-position: top right;
    padding: 50px 0 144px 0;
    background-size: 58%;
  }

  .Main-header {
    display: flex;
    flex-direction: row;
    justify-content: space-between;
  }


  .Main_nav_item {
    color: #fff;
    text-decoration: none;
    margin-right: 25px;
  }

  .Main_title {
    margin-top: 120px;
    margin-bottom: 120px;
  }
}

@media only screen and (min-width: 1450px) {
  .Main {
    background-image: url("./image/main_image.png");
    background-repeat: no-repeat;
    background-position: top right;
    padding: 71px 0 200px 0;
    background-size: 55%;
  }

  .Main-header {
    display: flex;
    flex-direction: row;
    justify-content: space-between;
  }


  .Main_nav_item {
    color: #fff;
    text-decoration: none;
    margin-right: 25px;
  }

  .Main_title {
    margin-top: 171px;
    margin-bottom: 200px;
  }
}