@media only screen and (min-width: 320px) {
    .LettersRecommendation {
        margin-bottom: 40px;
    }


    .LettersRecommendation-card {
        width: 100%;
        flex-direction: column;
        position: relative;
        padding: 4px;
    }

    .LettersRecommendation-img {
        width: 100%;
        height: 100%;
        box-shadow: 0 0 4px 1px rgba(0, 0, 0, 0.25);
        border-radius: 20px;
        object-fit: cover;
    }

    .LettersRecommendation-controls {
        display: flex;
        justify-content: start;
        margin-top: 20px;
    }

    .LettersRecommendation-controls button {
        width: 40px;
        height: 40px;
        border: 0;
        background-color: #fff;
        color: white;
        border-radius: 10px;
        cursor: pointer;
        transition: background-color 0.5s ease-out;
        box-shadow: 0 0 4px 1px rgba(0, 0, 0, 0.25);
    }

    .LettersRecommendation-controls button svg {
        width: 10px;
        height: 10px
    }

    .LettersRecommendation-controls button:nth-child(1) {
        margin-right: 10px;
    }
}

@media only screen and (min-width: 600px) {
    .LettersRecommendation {
        margin-bottom: 40px;
    }


    .LettersRecommendation-card {
        width: 100%;
        height: 100%;
        flex-direction: column;
        position: relative;
        padding: 4px;
    }

    .LettersRecommendation-img {
        width: 100%;
        height: 100%;
        box-shadow: 0 0 4px 1px rgba(0, 0, 0, 0.25);
        border-radius: 20px;
        object-fit: cover;
    }

    .LettersRecommendation-controls {
        display: flex;
        justify-content: start;
        margin-top: 20px;
    }

    .LettersRecommendation-controls button {
        width: 40px;
        height: 40px;
        border: 0;
        background-color: #fff;
        color: white;
        border-radius: 10px;
        cursor: pointer;
        transition: background-color 0.5s ease-out;
        box-shadow: 0 0 4px 1px rgba(0, 0, 0, 0.25);
    }

    .LettersRecommendation-controls button svg {
        width: 10px;
        height: 10px
    }

    .LettersRecommendation-controls button:nth-child(1) {
        margin-right: 10px;
    }
}

@media only screen and (min-width: 768px) {
    .LettersRecommendation {
        margin-bottom: 80px;
    }


    .LettersRecommendation-card {
        width: 400px;
        height: 550px;
        flex-direction: column;
        max-width: 25rem;
        position: relative;
        padding: 4px;
    }

    .LettersRecommendation-img {
        width: 100%;
        height: 100%;
        box-shadow: 0 0 4px 1px rgba(0, 0, 0, 0.25);
        border-radius: 20px;
        object-fit: cover;
    }

    .LettersRecommendation-controls {
        display: flex;
        justify-content: start;
        margin-top: 20px;
    }

    .LettersRecommendation-controls button {
        width: 60px;
        height: 60px;
        border: 0;
        background-color: #fff;
        color: white;
        border-radius: 10px;
        cursor: pointer;
        transition: background-color 0.5s ease-out;
        box-shadow: 0 0 4px 1px rgba(0, 0, 0, 0.25);
    }

    .IsoCertificates-controls button svg {
        width: 14px;
        height: 16px
    }

    .LettersRecommendation-controls button:nth-child(1) {
        margin-right: 20px;
    }
}

@media only screen and (min-width: 992px) {
    .LettersRecommendation {
        margin-bottom: 110px;
    }


    .LettersRecommendation-card {
        width: 400px;
        height: 550px;
        flex-direction: column;
        max-width: 25rem;
        position: relative;
        padding: 4px;
    }

    .LettersRecommendation-img {
        width: 100%;
        height: 100%;
        box-shadow: 0 0 4px 1px rgba(0, 0, 0, 0.25);
        border-radius: 20px;
        object-fit: cover;
    }

    .LettersRecommendation-controls {
        display: flex;
        justify-content: start;
        margin-top: 20px;
    }

    .LettersRecommendation-controls button {
        width: 60px;
        height: 60px;
        border: 0;
        background-color: #fff;
        color: white;
        border-radius: 10px;
        cursor: pointer;
        transition: background-color 0.5s ease-out;
        box-shadow: 0 0 4px 1px rgba(0, 0, 0, 0.25);
    }

    .IsoCertificates-controls button svg {
        width: 14px;
        height: 16px
    }

    .LettersRecommendation-controls button:nth-child(1) {
        margin-right: 20px;
    }
}

@media only screen and (min-width: 1200px) {
    .LettersRecommendation {
        margin-bottom: 110px;
    }


    .LettersRecommendation-card {
        width: 400px;
        height: 550px;
        flex-direction: column;
        max-width: 25rem;
        position: relative;
        padding: 4px;
    }

    .LettersRecommendation-img {
        width: 100%;
        height: 100%;
        box-shadow: 0 0 4px 1px rgba(0, 0, 0, 0.25);
        border-radius: 20px;
        object-fit: cover;
    }

    .LettersRecommendation-controls {
        display: flex;
        justify-content: start;
        margin-top: 20px;
    }

    .LettersRecommendation-controls button {
        width: 60px;
        height: 60px;
        border: 0;
        background-color: #fff;
        color: white;
        border-radius: 10px;
        cursor: pointer;
        transition: background-color 0.5s ease-out;
        box-shadow: 0 0 4px 1px rgba(0, 0, 0, 0.25);
    }

    .IsoCertificates-controls button svg {
        width: 14px;
        height: 16px
    }

    .LettersRecommendation-controls button:nth-child(1) {
        margin-right: 20px;
    }
}

@media only screen and (min-width: 1450px) {
    .LettersRecommendation {
        margin-bottom: 110px;
    }


    .LettersRecommendation-card {
        width: 400px;
        height: 550px;
        flex-direction: column;
        max-width: 25rem;
        position: relative;
        padding: 4px;
    }

    .LettersRecommendation-img {
        width: 100%;
        height: 100%;
        box-shadow: 0 0 4px 1px rgba(0, 0, 0, 0.25);
        border-radius: 20px;
        object-fit: cover;
    }

    .LettersRecommendation-controls {
        display: flex;
        justify-content: start;
        margin-top: 20px;
    }

    .LettersRecommendation-controls button {
        width: 60px;
        height: 60px;
        border: 0;
        background-color: #fff;
        color: white;
        border-radius: 10px;
        cursor: pointer;
        transition: background-color 0.5s ease-out;
        box-shadow: 0 0 4px 1px rgba(0, 0, 0, 0.25);
    }

    .IsoCertificates-controls button svg {
        width: 14px;
        height: 16px
    }

    .LettersRecommendation-controls button:nth-child(1) {
        margin-right: 20px;
    }
}