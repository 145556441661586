@media only screen and (min-width: 320px) {
    .Contact {
        margin-bottom: 80px;
    }

    .Contact-data {
        width: 100%;
        border-radius: 20px;
        box-shadow: 0 0 4px 1px rgba(0, 0, 0, 0.25);
        padding: 20px;
    }

    .Contact-data-mr-b {
        margin-bottom: 5px;
    }

    .Contact-data-mr-t {
        margin-top: 40px;
    }

    .Contact-Button-main {
        display: inline-block;
        border-radius: 100px;
        padding: 9px 9px 9px 25px;
        box-shadow: 0 0 4px 1px rgba(0, 0, 0, 0.25);
        margin-top: 33px;
    }

    .Contact-Button-wrap {
        display: flex;
        align-items: center;
    }

    .Contact-Button-title {
        margin-right: 25px;
        color: #000;
        text-decoration: none;
    }

    .Contact-map {
        width: 100%;
        height: 400px;
        border-radius: 20px;
        margin-top: 20px;
        box-shadow: 0 0 4px 1px rgba(0, 0, 0, 0.25);
    }
}

@media only screen and (min-width: 600px) {
    .Contact {
        margin-bottom: 80px;
    }


    .Contact-data {
        width: 100%;
        border-radius: 20px;
        box-shadow: 0 0 4px 1px rgba(0, 0, 0, 0.25);
        padding: 20px;
    }

    .Contact-data-mr-b {
        margin-bottom: 5px;
    }

    .Contact-data-mr-t {
        margin-top: 40px;
    }

    .Contact-Button-main {
        display: inline-block;
        border-radius: 100px;
        padding: 9px 9px 9px 25px;
        box-shadow: 0 0 4px 1px rgba(0, 0, 0, 0.25);
        margin-top: 33px;
    }

    .Contact-Button-wrap {
        display: flex;
        align-items: center;
    }

    .Contact-Button-title {
        margin-right: 25px;
        color: #000;
        text-decoration: none;
    }

    .Contact-map {
        width: 100%;
        height: 400px;
        border-radius: 20px;
        margin-top: 20px;
        box-shadow: 0 0 4px 1px rgba(0, 0, 0, 0.25);
    }
}

@media only screen and (min-width: 768px) {
    .Contact {
        margin-bottom: 80px;
    }

    .Contact-wrapper {
        display: flex;
    }

    .Contact-data {
        width: 350px;
        height: 375px;
        border-radius: 20px;
        box-shadow: 0 0 4px 1px rgba(0, 0, 0, 0.25);
        padding: 20px;
    }

    .Contact-data-mr-b {
        margin-bottom: 5px;
    }

    .Contact-data-mr-t {
        margin-top: 40px;
    }

    .Contact-Button-main {
        display: inline-block;
        border-radius: 100px;
        padding: 9px 9px 9px 25px;
        box-shadow: 0 0 4px 1px rgba(0, 0, 0, 0.25);
        margin-top: 33px;
    }

    .Contact-Button-wrap {
        display: flex;
        align-items: center;
    }

    .Contact-Button-title {
        margin-right: 25px;
        color: #000;
        text-decoration: none;
    }

    .Contact-map {
        width: 621px;
        height: 375px;
        margin-left: 30px;
        border-radius: 20px;
        box-shadow: 0 0 4px 1px rgba(0, 0, 0, 0.25);
        margin-top: 0;
    }
}

@media only screen and (min-width: 992px) {
    .Contact {
        margin-bottom: 110px;
    }

    .Contact-wrapper {
        display: flex;
    }

    .Contact-data {
        width: 300px;
        height: 375px;
        border-radius: 20px;
        box-shadow: 0 0 4px 1px rgba(0, 0, 0, 0.25);
        padding: 20px;
    }

    .Contact-data-mr-b {
        margin-bottom: 5px;
    }

    .Contact-data-mr-t {
        margin-top: 20px;
    }

    .Contact-Button-main {
        display: inline-block;
        border-radius: 100px;
        padding: 9px 9px 9px 25px;
        box-shadow: 0 0 4px 1px rgba(0, 0, 0, 0.25);
        margin-top: 33px;
    }

    .Contact-Button-wrap {
        display: flex;
        align-items: center;
    }

    .Contact-Button-title {
        margin-right: 25px;
        color: #000;
        text-decoration: none;
    }

    .Contact-map {
        width: 621px;
        height: 375px;
        margin-left: 143px;
        border-radius: 20px;
        box-shadow: 0 0 4px 1px rgba(0, 0, 0, 0.25);
    }
}

@media only screen and (min-width: 1200px) {
    .Contact {
        margin-bottom: 110px;
    }

    .Contact-wrapper {
        display: flex;
    }

    .Contact-data {
        width: 300px;
        height: 375px;
        border-radius: 20px;
        box-shadow: 0 0 4px 1px rgba(0, 0, 0, 0.25);
        padding: 20px;
    }

    .Contact-data-mr-b {
        margin-bottom: 5px;
    }

    .Contact-data-mr-t {
        margin-top: 20px;
    }

    .Contact-Button-main {
        display: inline-block;
        border-radius: 100px;
        padding: 9px 9px 9px 25px;
        box-shadow: 0 0 4px 1px rgba(0, 0, 0, 0.25);
        margin-top: 33px;
    }

    .Contact-Button-wrap {
        display: flex;
        align-items: center;
    }

    .Contact-Button-title {
        margin-right: 25px;
        color: #000;
        text-decoration: none;
    }


    .Contact-map {
        width: 621px;
        height: 375px;
        margin-left: 143px;
        border-radius: 20px;
        box-shadow: 0 0 4px 1px rgba(0, 0, 0, 0.25);
    }
}

@media only screen and (min-width: 1450px) {
    .Contact {
        margin-bottom: 110px;
    }

    .Contact-wrapper {
        display: flex;
    }

    .Contact-data {
        width: 300px;
        height: 375px;
        border-radius: 20px;
        box-shadow: 0 0 4px 1px rgba(0, 0, 0, 0.25);
        padding: 20px;
    }

    .Contact-data-mr-b {
        margin-bottom: 5px;
    }

    .Contact-data-mr-t {
        margin-top: 20px;
    }

    .Contact-Button-main {
        display: inline-block;
        border-radius: 100px;
        padding: 9px 9px 9px 25px;
        box-shadow: 0 0 4px 1px rgba(0, 0, 0, 0.25);
        margin-top: 33px;
    }

    .Contact-Button-wrap {
        display: flex;
        align-items: center;
    }

    .Contact-Button-title {
        margin-right: 25px;
        color: #000;
        text-decoration: none;
    }


    .Contact-map {
        width: 621px;
        height: 375px;
        margin-left: 143px;
        border-radius: 20px;
        box-shadow: 0 0 4px 1px rgba(0, 0, 0, 0.25);
    }
}