@font-face {
  font-family: "ManropeBold";
  src: local("ManropeBold"),
    url("./fonts/Manrope/static/Manrope-Bold.ttf") format("truetype");
}

@font-face {
  font-family: "ManropeExtraBold";
  src: local("ManropeExtraBold"),
    url("./fonts/Manrope/static/Manrope-ExtraBold.ttf") format("truetype");
}

@font-face {
  font-family: "ManropeExtraLight";
  src: local("ManropeExtraLight"),
    url("./fonts/Manrope/static/Manrope-ExtraLight.ttf") format("truetype");
}

@font-face {
  font-family: "ManropeLight";
  src: local("ManropeLight"),
    url("./fonts/Manrope/static/Manrope-Light.ttf") format("truetype");
}

@font-face {
  font-family: "ManropeMedium";
  src: local("ManropeMedium"),
    url("./fonts/Manrope/static/Manrope-Medium.ttf") format("truetype");
}

@font-face {
  font-family: "ManropeRegular";
  src: local("ManropeRegular"),
    url("./fonts/Manrope/static/Manrope-Regular.ttf") format("truetype");
}

@font-face {
  font-family: "ManropeSemiBold";
  src: local("ManropeSemiBold"),
    url("./fonts/Manrope/static/Manrope-SemiBold.ttf") format("truetype");
}



body {
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen',
    'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
}

@media only screen and (max-width: 600px) {
  .title {
    font-family: "ManropeBold";
    font-size: 18px;
    line-height: 25px;
  }

  .button_text {
    font-family: "ManropeMedium";
    font-size: 13px;
  }

  .car_title {
    font-family: "ManropeMedium";
    font-weight: 700;
    font-size: 23px;
    color: #000;
    margin-bottom: 20px;
  }

  .car_title_red {
    color: #AB0B0A;
  }

  .name_text {
    font-family: "ManropeRegular";
    font-size: 16px;
    font-weight: 700;
  }

  .main_text {
    font-family: "ManropeRegular";
    font-size: 14px;
    font-weight: 400;
  }

  .link_text {
    font-family: "ManropeLight";
    font-size: 14px;
    font-weight: 300;
    color: #000;
    text-decoration: none;
  }
}

@media only screen and (min-width: 600px) {
  .title {
    font-family: "ManropeBold";
    font-size: 20px;
    line-height: 35px;
  }

  .button_text {
    font-family: "ManropeMedium";
    font-size: 13px;
  }

  .car_title {
    font-family: "ManropeMedium";
    font-weight: 700;
    font-size: 30px;
    color: #000;
    margin-bottom: 20px;
  }

  .car_title_red {
    color: #AB0B0A;
  }

  .name_text {
    font-family: "ManropeRegular";
    font-size: 20px;
    font-weight: 700;
  }

  .main_text {
    font-family: "ManropeRegular";
    font-size: 16px;
    font-weight: 400;
  }

  .link_text {
    font-family: "ManropeLight";
    font-size: 16px;
    font-weight: 300;
    color: #000;
    text-decoration: none;
  }
}

@media only screen and (min-width: 768px) {
  .title {
    font-family: "ManropeBold";
    font-size: 25px;
    line-height: 41px;
  }

  .button_text {
    font-family: "ManropeMedium";
    font-size: 10px;
  }

  .car_title {
    font-family: "ManropeMedium";
    font-weight: 700;
    font-size: 35px;
    color: #000;
    margin-bottom: 50px;
  }

  .car_title_red {
    color: #AB0B0A;
  }

  .name_text {
    font-family: "ManropeRegular";
    font-size: 23px;
    font-weight: 700;
  }

  .main_text {
    font-family: "ManropeRegular";
    font-size: 20px;
    font-weight: 400;
  }

  .link_text {
    font-family: "ManropeLight";
    font-size: 16px;
    font-weight: 300;
    color: #000;
    text-decoration: none;
  }
}

@media only screen and (min-width: 992px) {
  .title {
    font-family: "ManropeBold";
    font-size: 35px;
    line-height: 51px;
  }

  .button_text {
    font-family: "ManropeMedium";
    font-size: 13px;
  }

  .car_title {
    font-family: "ManropeMedium";
    font-weight: 700;
    font-size: 35px;
    color: #000;
    margin-bottom: 60px;
  }

  .car_title_red {
    color: #AB0B0A;
  }

  .name_text {
    font-family: "ManropeRegular";
    font-size: 23px;
    font-weight: 700;
  }

  .main_text {
    font-family: "ManropeRegular";
    font-size: 20px;
    font-weight: 400;
  }

  .link_text {
    font-family: "ManropeLight";
    font-size: 16px;
    font-weight: 300;
    color: #000;
    text-decoration: none;
  }
}

@media only screen and (min-width: 1200px) {
  .title {
    font-family: "ManropeBold";
    font-size: 45px;
    line-height: 61px;
  }

  .button_text {
    font-family: "ManropeMedium";
    font-size: 16px;
  }

  .car_title {
    font-family: "ManropeMedium";
    font-weight: 700;
    font-size: 35px;
    color: #000;
    margin-bottom: 70px;
  }

  .car_title_red {
    color: #AB0B0A;
  }

  .name_text {
    font-family: "ManropeRegular";
    font-size: 23px;
    font-weight: 700;
  }

  .main_text {
    font-family: "ManropeRegular";
    font-size: 20px;
    font-weight: 400;
  }

  .link_text {
    font-family: "ManropeLight";
    font-size: 16px;
    font-weight: 300;
    color: #000;
    text-decoration: none;
  }
}

@media only screen and (min-width: 1450px) {
  .title {
    font-family: "ManropeBold";
    font-size: 45px;
    line-height: 61px;
  }

  .button_text {
    font-family: "ManropeMedium";
    font-size: 16px;
  }

  .car_title {
    font-family: "ManropeMedium";
    font-weight: 700;
    font-size: 35px;
    color: #000;
    margin-bottom: 70px;
  }

  .car_title_red {
    color: #AB0B0A;
  }

  .name_text {
    font-family: "ManropeRegular";
    font-size: 23px;
    font-weight: 700;
  }

  .main_text {
    font-family: "ManropeRegular";
    font-size: 20px;
    font-weight: 400;
  }

  .link_text {
    font-family: "ManropeLight";
    font-size: 16px;
    font-weight: 300;
    color: #000;
    text-decoration: none;
  }
}